import React, { useState } from 'react';
import AppLink from './AppLink';
import { CardsList } from '../helpers/card.helper';

function Content() {
  const CSSBlock = 'main-content';
  const [activeSection, setActiveSection] = useState<number>(0);

  return (
    <div className={`${CSSBlock}`}>
      <AppLink
        url="https://emdee.io"
        description="A Markdown editor available on all platforms, with a clean and intuitive user experience."
        text={
          <span>
            e<b>md</b>ee.io
          </span>
        }
        card={CardsList.EMDEE}
      />
      <AppLink
        url="https://whattheport.com"
        description="Having trouble understanding the different versions of USB? Hopefully this can help."
        text={<span>What The Port</span>}
        card={CardsList.WHAT_THE_PORT}
      />
      {/* <AppLink
        url=""
        description=""
        private
        text={<span>bizav.ai</span>}
        card={CardsList.JETQUITY}
      /> */}
      {/* <AppLink
        url="https://demmi.app"
        description="The natural marketplace."
        private
        text={<span>demmi</span>}
        card={CardsList.DEMMI}
      /> */}
      {/* <AppLink
        url=""
        description=""
        private
        text={<span>Macro'd</span>}
        card={CardsList.MACROD}
      /> */}
      {/* <AppLink
      url="https://whattheport.com"
      description="Having trouble understanding the different versions of USB? Hopefully this can help."
      text={<span>Windows 11 View</span>}
    />
    <AppLink
      url="https://whattheport.com"
      description="Having trouble understanding the different versions of USB? Hopefully this can help."
      text={<span>Car Simulator</span>}
    /> */}
    </div>
  );
}

export default Content;
