import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Simulator from '../threejs/simulator';
import { CardContentRef } from '../components/AppLink';

const CardContentWTP = forwardRef<CardContentRef>((_, ref): JSX.Element => {
  let canvasRef = useRef(null);
  const [simulation, setSimulation] = useState<Simulator>();

  useImperativeHandle(ref, () => ({
    onMove(x: number, y: number) {
      simulation && simulation.onUpdateOffset(x, y);
    },

    onLeave(x: number, y: number) {
      simulation && simulation.onUpdateOffset(x, y);
    },
  }));

  useEffect(() => {
    if (canvasRef.current)
      setSimulation(new Simulator(canvasRef.current, 'USB-C.gltf'));
  }, [canvasRef]);

  return <canvas ref={canvasRef}></canvas>;
});

export default CardContentWTP;
