import React, {
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { CardContentRef } from '../components/AppLink';
import JetquityJet from '../components/JetquityJet';

const CardContentJetquity = forwardRef<CardContentRef>((_, ref) => {
  const CSSBlock = 'card-content-jetquity';
  const [skewValue, setSkewValue] = useState([0, 0]);

  useImperativeHandle(ref, () => ({
    onMove(x: number, y: number) {
      setSkewValue([x, y]);
    },

    onLeave(x: number, y: number) {
      setSkewValue([x, y]);
    },
  }));

  return (
    <div className={CSSBlock}>
      <div className={`${CSSBlock}__background`} />
      <div className={`${CSSBlock} `}>
        <div className={`clouds`}>
          <div className={`clouds-1`} />
          <div className={`clouds-2`} />
          <div className={`clouds-3`} />
          <div className={`clouds-hue`} />
        </div>
        <div className={`${CSSBlock}__wind`}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {/* <div className={`${CSSBlock}__subtitle`}>Jet Setting ...</div> */}
      </div>
      <JetquityJet animated={true} />
    </div>
  );
});

export default CardContentJetquity;
